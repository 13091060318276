<template>
  <header v-if="this.$route.name != 'RelaxExamsStepsExams'" class="main-header bg-white d-flex justify-content-between align-items-center p-1">
    <div class="header-toggle m-0 p-0">
      <div class="menu-toggle mobile-menu-icon">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>


    <div class="header-part-right">

      <div>
        <span ></span>
      </div>
      <!-- Full screen toggle-->
      <div id="data_fullscreen">
         <i class="i-Full-Screen header-icon d-none d-sm-inline-block"></i> 
      </div>
      <div  class="btn-group">
           <i class="fas fa-globe-americas text-muted header-icon" style="font-size:16px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"></i>
        <div v-if="languages && languages.length"  class="dropdown-menu dropdown-menu-right text-center" x-placement="top-start">
          <a v-for="language in languages" :key="language.id" @click.prevent="switchLocale(language)" class="dropdown-item text-start" href="#">{{ language.languageName }}</a>
        </div>
      </div>
      <!-- Grid menu Dropdown-->
<!--        <div class="dropdown dropleft">-->
<!--             <i class="i-Safe-Box text-muted header-icon" id="dropdownMenuButton" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i> -->
<!--            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">-->
<!--                <div class="menu-icon-grid">-->
<!--                    <a href="#" class="btn-outline-success mb-2">  <i class="i-Shop-4"></i>  Home</a>-->
<!--                    <a href="#" class="btn-outline-success mb-2"> <i class="i-Library"></i>  UI Kits </a>-->
<!--                    <a href="#" class="btn-outline-success mb-2">  <i class="i-Drop"></i>  Apps</a>-->
<!--                    <a href="#" class="btn-outline-success mb-2"> <i class="i-File-Clipboard-File&#45;&#45;Text"></i>  Forms</a>-->
<!--                    <a href="#" class="btn-outline-success mb-2"> <i class="i-Checked-User"></i>  Sessions</a>-->
<!--                    <a href="#" class="btn-outline-success mb-2"> <i class="i-Ambulance"></i>  Support</a>-->
<!--                </div>-->
<!--            </div>-->
<!--      </div>-->

      <!-- Notification -->


      <div class="dropdown notification">
        <div  class="badge-top-container cursor-pointer" role="button" id="dropdownNotification" data-toggle="dropdown"
             aria-haspopup="true" aria-expanded="false">
          <span class="badge badge-primary pt-1 notification-num" >  5</span>
           <i class="i-Bell text-muted header-icon"></i> 
        </div>
        <!-- Notification dropdown -->
        <div class="dropdown-menu dropdown-menu-right notification-dropdown" aria-labelledby="dropdownNotification" data-perfect-scrollbar data-suppress-scroll-x="true">

          <div class="dropdown-item d-flex" >
            <div class="notification-details flex-grow-1">
              <p class="m-0 d-flex align-items-center">
                <span>message student</span>
                <span class="flex-grow-1"></span>
              </p>
              <p class="text-small text-muted m-0"> <i class="fa mr-1" :class="false ?? 'i-Data-Power' "></i>  25/7/2023</p>
            </div>
          </div>
         <div>
           <button  class="btn btn-success w-100"> <i class="fa fa-eye"></i>  show all</button>
         </div>
        </div>
      </div>
      <!-- Notification End -->
      <!-- User avatar dropdown -->
      <div class="dropdown">
        <div class="user align-self-end">
            <div class="photo" id="userDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img v-if="false">
                <img v-else :src="`${publicPath}assets/images/faces/1.jpg`" height="36">
            </div>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
            <div class="dropdown-header" >
              <strong v-if="user">{{user.name}}</strong> <i  class="i-Lock-User mr-1"></i>
             
            </div>
            <a class="dropdown-item"  data-toggle="modal" data-target="#editProfile">{{$t('chgProfileData.text')}} </a>
            <!-- <a class="dropdown-item" >{{$t('accountSettings.text')}}</a>
            <a class="dropdown-item" >{{$t('myInvoices.text')}}</a> -->
            <a class="dropdown-item" @click.prevent="signOut()"> {{$t('logout.text')}}</a>
          </div>
        </div>
      </div>



    </div>
  </header>
  <Teleport to=".app-all-wrap">

<div class="modal fade" id="editProfile" role="dialog" aria-labelledby="editProfileLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="text-start">
                    <h4 class="modal-title m-0 p-0" id="exampleModalLabel"> {{ $t('chgProfileData.text') }} </h4>
                    <small class="text-mute">
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Temporibus
                        exercitationem laboriosam sequi soluta ducimus magnam.</small>
                </div>
                <i class="fas fa-times p-2 text-mute" data-dismiss="modal" aria-label="Close"></i>
            </div>
            <div class="modal-body text-initial">
                <form>

                    <div class="form-group">
                        <label> {{ $t('name.text') }}</label>
                        <div class="input-group mb-1">
                            <input type="text" v-model="form.name" name="name"/>
                        </div>
                    </div>
                    <div class="form-group">
                        <label> {{ $t('userName.text') }}</label>
                        <div class="input-group mb-1">
                            <input type="text" v-model="form.username" name="username"/>
                        </div>
                    </div>
                    <div class="form-group">
                        <label> {{ $t('email.text') }}</label>
                        <div class="input-group mb-1">
                            <input type="text" v-model="form.email" name="email"/>
                        </div>
                    </div>
                    <div class="form-group">
                        <label> {{ $t('phone.text') }}</label>
                        <div class="input-group mb-1">
                            <input type="text" v-model="form.phone" name="phone"/>
                        </div>
                    </div>
                    <!-- <div class="form-group">{{ language }}
                                <label> {{ $t('language.text') }}</label>
                                <div class="input-group mb-1">
                                    <Multiselect ref="multiselect" :noOptionsText="$t('noOptionsText.text')"
                                        :noResultsText="$t('noResultsText.text')" 
                                        v-model="form.def_lang" mode="single" :options="language"
                                        :loading="loading" :searchable="true" valueProp="id" label="languageName" track-by="name"
                                         />
                                </div>
                            </div> -->
                    <div class="form-group">
                        <label> {{ $t('password.text') }}</label>
                        <div class="input-group mb-1">
                            <input type="text" v-model="form.password" name="password"/>
                        </div>
                    </div>
                    <div class="form-group">
                        <label> {{ $t('password.text') }}</label>
                        <div class="input-group mb-1">
                            <input type="text" v-model="form.password_confirmation" name="password"/>
                        </div>
                    </div>

                </form>
            </div>
            <div class="modal-footer justify-content-end">
                <button class="btn btn-secondary" type="button" data-dismiss="modal">{{ $t('cancel.text')
                }}</button>

                <button class="btn btn-primary ml-2" type="button" @click.prevent="editProfileDate()">
                    <i class="fas fa-play"></i> {{ $t('chgProfileData.text') }}
                </button>

            </div>
        </div>
    </div>
</div>
</Teleport>
</template>

<script>

import {mapActions , mapState,mapGetters} from "vuex";

export default {
  name: "app-header",
  data() {
    return {
      publicPath: process.env.BASE_URL,
      form:{
        name:'',
        username:'',
        email:'',
        phone:'',
        password:'',
        password_confirmation:'',
        def_lang:'',
      }
    }
  },

  created() {
    this.languagesGet();
    if(this.user){
    this.form.name = this.user.name;
    this.form.username = this.user.username;
    this.form.phone = this.user.phone;
    this.form.email = this.user.email;
    this.form.def_lang = this.user.def_lang;

    }


  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
      languagesGet: 'dashboard/languages',
      setLanguage:'auth/setLanguage',
      setDirection:'auth/setDirection'
    }),
    editProfileDate(){
      this.$axios.post('auth/edit-profile', this.form).then(response => {
        if (response.data.code === 200) {
         
          this.$toast.success(response.data.messages);
        } else {
          this.$toast.error(response.data.messages);
        }
      });
    },
    signOut() {
      this.logout().then(() => {
        this.$router.replace('/login');
      });
    },
    switchLocale(language) {
      // document.getElementById('sidebarIcon').style.display = 'block';
      // document.getElementById('sidebarMenu').style.display = 'none';
      console.log(language);

      this.$axios.post('auth/edit-profile', {'def_lang':  language.language_universal}).then(response => {
        if (response.data.code === 200) {
          this.setLanguage(language.language_universal)
          this.setDirection({'rtl':language.rtl , 'lang' : language.language_universal});
          this.$toast.success(response.data.messages);
        } else {
          this.$toast.error(response.data.messages);
        }
      });
    },


  },
  computed: {
    ...mapState('auth', {
      user : 'user',
    }),
    ...mapState('dashboard', {
      languages : 'languages',
    }),
    ...mapGetters['user']

  },
}
</script>