import axios from 'axios';

const httpClient = axios.create({
    baseURL: process.env.VUE_APP_API,
    headers: {
        "Content-Type": "application/json",
    },
    params: {
        'api-key': "jjjjjjjjjjjjj"
    }
});


const getAuthToken = () => localStorage.getItem('token');

if(getAuthToken) {
    const authInterceptor = (config) => {
        config.headers['Authorization'] = `Bearer ` + getAuthToken();
        return config;
    }

    httpClient.interceptors.request.use(authInterceptor);

    httpClient.interceptors.response.use(
        response => response,
        error => {throw error}
    )
}
export default httpClient;